@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Eventually by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Header */

	#header {
		h1 {
			font-size: 3.25em;
			margin: 0 0 (_size(element-margin) * 0.275) 0;
		}

		p {
			font-size: 1.35em;
			line-height: 1.65em;
		}

		a {
			color: inherit;
		}

		@include breakpoint(small) {
			h1 {
				font-size: 2em;
			}

			p {
				font-size: 1em;
			}
		}

		@include breakpoint(xsmall) {
			margin: 0 0 (_size(element-margin) * 0.5) 0;
		}
	}