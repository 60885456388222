/* Avatar -- from Identity Theme */
#header .avatar {
	position: relative;
	display: block;
	margin-bottom: 1.5em;
}

	#header .avatar img {
		display: block;
		margin: 0 auto;
		border-radius: 100%;
		box-shadow: 0 0 0 1.5em rgba(0, 0, 0, 0.25)
	}
