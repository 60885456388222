@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Eventually by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Icon */

	ul.icons {
		li {
			a {
				@include icon;
				border-bottom: none;

				svg {
						position: relative;

						fill: currentColor;
						width: 1em;
						height: 1em;
						overflow: hidden;
				}
			}
		}
	}
	