@import '../libs/vars';
@import '../libs/functions';
@import '../libs/mixins';

///
/// Eventually by HTML5 UP
/// html5up.net | @n33co
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* List */

	ol {
		list-style: decimal;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1.25em;

		li {
			padding-left: 0.25em;
		}
	}

	ul {
		list-style: disc;
		margin: 0 0 _size(element-margin) 0;
		padding-left: 1em;

		li {
			padding-left: 0.5em;
		}

		&.icons {
			cursor: default;
			list-style: none;
			padding-left: 0;

			li {
				display: inline-block;
				padding: 0 1em 0 0;

				&:last-child {
					padding-right: 0;
				}

				.icon {
					&:before {
						font-size: 1.25em;
					}
				}

				a {
					color: inherit;
				}
			}
		}
	}